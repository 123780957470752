<!--
 * @Author: wh
 * @Date: 2020-05-25 21:55:00
 * @LastEditTime: 2020-07-15 17:11:52
 * @LastEditors: Please set LastEditors
 * @Description: p2p contacts person
 * @FilePath: \Web\JT.Web\src\views\messageCenter\pages\page\components\dialogue\Contacts.vue
-->
<template>
    <div>
        <ul>
            <li
                class="noData ng-scope"
                v-if="contactsList.length<=0"
            >
                暂无联系人
            </li>
            <li
                v-for="contact in contactsList"
                :key="contact.friend_id"
                @click="enterChat(contact, contact.id)"
                :class="{'active': activeIndex === contact.id}"
            >
                <div class="HeadPortrait">
                    <img v-if="contact.AvatarUrl" :src="contact.AvatarUrl" alt="">
                    <p v-else :style="`background: ${contact.User_Gender === 0 ? '#fe86a8' : '#4da9ff'}`">
                        {{ contact.User_Name }}
                    </p>
                </div>
                <div class="information" :class="{information2: contact.Dept_Name}">
                    <div class="infoTitle">
                        <p :title="contact.User_Name">
                            <span>{{ contact.User_Name }}</span>
                        </p>
                    </div>
                    <div class="user-org" v-if="contact.Dept_Name">
                        <el-tag type="info" :title="contact.Dept_Name">
                            {{ contact.Dept_Name }}
                        </el-tag>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    props: {
        contactsList: {
            type: [Array],
        },
    },
    data() {
        return {
            activeIndex: null,
        };
    },
    created() {
    },
    computed: {
        teamList: function () {
            return window.$globalHub.$store.state.teamlist && window.$globalHub.$store.state.teamlist.filter(team => {
                return team.validToCurrentUser;
            });
        },
    },
    watch: {
        contactsList() {
            this.activeIndex = null;
        },
        tabIndex() {
            this.activeIndex = null;
        },
    },
    methods: {
        // 发起聊天
        enterChat(rowData, index) {
            this.activeIndex = index;
            this.$emit('clickContact', 'p2p-' + rowData.id, rowData);
            // if (sessionID !== 'p2p-') {
            //     this.$emit('clickContact', sessionID, rowData);
            //     this.activeIndex = index;
            // } else {
            //     this.$message.warning('该联系人未注册云信ID，请联系管理员!');
            // }
        },
    },
    filters: {
        spliceOrgName(value) {// value是输入框的内容，也是要显示的内容
            if (value) {
                let str = value.toString();
                if (str.length > 6) {
                    str = str.substr(0, 6) + '...';
                }
                return str; // 数组转字符串 以空格输出。。。
            }
            return '';
        },
    },
};
</script>
<style lang="stylus">
.el-tag.el-tag--info
    width: 100%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
.user-org
    top calc(50% - 16px) !important
</style>
